.ais-Hits-list {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  width: 100%;
}
.ais-Hits-item {
  transition: none!important;
  /* flex: 33.333%; */
  /* display:flex;
  justify-content:space-between; */
  /* max-width: 33.333%; */
  /* margin-bottom: 3rem; */
  /* margin-left: 1.5rem; */
  /* padding-right: 1rem; */

  /* padding-bottom: 3rem; */
  position: relative;
   margin: 0 0 30px;
   height: calc(100% - 30px);
  /* padding-left: 1rem; */
}
/* .ais-Hits-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  left: 0;
  right: 2rem;
  background-color: rgba(0, 0, 0, .1);
}
 */
/* .ais-Hits-item::before {
  content: "\2022";
  position: absolute;
  top: -.3rem;
  left: 0;
  color: rgb(65, 68, 78);
  font-size: 1.3rem;
} */
.ais-Hits-item h2 {
  font-size: 1rem;
  font-weight: 600;
}
.ais-Hits-item p {
  font-size: .9rem;
  padding-right: 3rem;
  font-weight: 300;
}
