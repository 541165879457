.cart-dropdown {
    position: absolute;
    width: 240px;
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid black;
    background-color: white;
    top: 120px;
    right: 210px;
    z-index: 5;
}

.cart-dropdown .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.cart-dropdown button {
    margin-top: auto;
}